import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import itemStyles from '../components/item/cases.module.css';
import styles from './anuel.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import Blockquote from '../components/blockquote';
//other
import { meta } from '../../../metaData';

function AnuelCase() {
  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "anuel-screen-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "anuel-screen-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1830, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "anuel-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "anuel-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bukenya: file(relativePath: { eq: "anuel-bukenya.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "anuel-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      isRed
      score={['E-commerce', 'Uganda, USA', 'April, 2018', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Fine platform&nbsp;— delivering digital transformation and business processes optimization"
      subTitle="How Brocoders helped Anuel Energy to convert their business processes into the solid software ecosystem"
      metaData={meta.anuel}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Anuel Energy Uganda is a privately-held renewable energy company headquartered in Kampala.
          Our team was pleased to meet a client with such an ambitious business spirit, as well as
          help them bring their revolutionary ideas to life.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          Anuel Energy required a technical partner, who’d share their strategy and support the
          overall product vision.
        </p>
        <p className={itemStyles.paragraph}>
          Anuel Energy business deploys solar energy systems that meet the energy needs of the
          markets it serves, as well as invests in profitable renewable energy and energy-efficient
          solutions.
        </p>
        <p className={itemStyles.paragraph}>
          The Anuel Energy’s ground-breaking goal is to impact one million Ugandans by 2020.
          Moreover, our client is eager to become the number one choice for Uganda’s household,
          commercial and institutional solar segments that opt for reliable off-grid installations.
          Anuel Energy is committed to becoming a leading clean lifestyle brand that creates &
          stocks affordable, safe and efficient products of high quality.
        </p>
        <p className={itemStyles.paragraph}>
          To stay ahead of the curve, the Anuel Energy’s team decided to streamline the primary
          business flows back in 2018. Therefore, they were looking for a full-cycle software
          development team to handle business digital transformation and optimization from the idea
          right to the implementation.
        </p>
        <p className={itemStyles.paragraph}>
          After a few workshops with different companies, the client reached out to us to negotiate
          the details and define the key ideas. As a result, Brocoders emerged as a natural choice
          to carry out the project thanks to our cost-effective development practices and innovative
          approaches. To stay on the same page with the Anuel Energy’s team, we worked out the
          further strategy, along with the project’s schedule in the first place.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Solution</h2>
        <p className={itemStyles.paragraph}>
          Brocoders supported the full-cycle development process of the clients’ business ideas,
          which faced several iterations of Change Requests and Market Fit evaluations. Our business
          analysts were involved from the initial research and modeling phases, Market Fit
          evaluation right to the final stage of Customer Support.
        </p>
        <p className={itemStyles.paragraph}>
          The dedicated software development team provided full-fledged software architecture and
          proper implementation based on prototypes and software requirement specifications. Thanks
          to the agile frameworks, which are inherited for all the company’s projects, all the
          delivery dates and increments were planned, scheduled and synchronized to the customer’s
          and external requirements.
        </p>
        <p className={itemStyles.paragraph}>
          In order to get the whole system done and entirely settled, the Brocoders team conducted
          workshops and interviews all along the road. To ensure the best possible market fit
          results, we had to readjust the process flow and system design several times during the
          development stages. Besides, the stakeholders and in-house employees helped to fix any
          emerged bugs by conducting various tests of the existing software product.
        </p>
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          <strong>The resulting solution is comprised of two smoothly working platforms:</strong>
        </p>
        <p className={itemStyles.paragraph}>
          <div>
            <strong>Fine web system.</strong>
          </div>
          The Fine web application helps Anuel Energy to automate and centralize their control over
          the involved parties and supporting activities, as well as remotely manage inventory and
          forecast supplies. The system also creates additional value with a new involved party,
          which allows platform users to benefit from postponed or installment repayments. Moreover,
          it provides associated functionality for stock management and control.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          <div>
            <strong>Fine mobile application.</strong>
          </div>
          The Fine mobile application allows to easily onboard new operators and is considered to
          provide more agility to the field operation processes. The implemented functionality
          covers major flows, which were already settled within the company. At the same time, the
          app handles additional ready-to-use and easy-to-customize options, designed for any other
          participants with different business flows or domains.
        </p>
      </div>
      <Img fluid={data.screen4.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          To exceed the client’s expectations, we assembled a proactive team of on-site seasoned
          professionals with years of software development experience. Our specialists examined the
          ins and outs of the current business issue to let people stay on top of their mental
          health.
        </p>
        <p className={itemStyles.paragraph}>
          They demonstrated their ability to meet tight deadlines while responding to the
          ever-changing business requirements without sacrificing the product quality. The team was
          comprised of:
        </p>
        <Team
          color="#5475E1"
          list={[
            { count: 1, title: 'Frontend\ndeveloper' },
            { count: 1, title: 'Backend\ndeveloper' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />
        <Resume
          services={['product', 'mobileDev', 'webDev']}
          technology={['react', 'rails', 'figma', 'reactNative']}
        />
      </div>
      <Blockquote
        img={data.bukenya.childImageSharp.fluid}
        isRating
        text={`"The platform has received positive feedback. For such an extensive, complicated project, Brocoders has taken scope changes in stride and has effectively implemented change requests. They're organized and communicative, even corresponding during after-hours. They're a flexible and patient partner."`}
        downloadReviewLink="https://bit.ly/3DifPsn"
        author={
          <React.Fragment>
            <strong>Emmanuel Bukenya</strong> <br />
            CEO, Fine - Renewable Energy Startup
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business value</h2>
        <p className={itemStyles.paragraph}>
          The whole backlog was kicked off within the six months of continuous development.
          Brocoders provided a certain level of agility within the management and development
          processes. That said, all the changes to the initial roadmap and planning were iteratively
          included in the final scope of work, including the proper corrections to the delivery plan
          and final budget.
        </p>
        <p className={itemStyles.paragraph}>
          The Fine solution achieved agility within the whole business process and made it more
          responsive to the external requirements of different businesses. Therefore, 2x more flows
          were fully covered and allowed for a certain level of customization within the system.
          Moreover, 80% of field operations were covered by the mobile application and didn’t
          require onsite manipulation any longer.
        </p>
        <p className={itemStyles.paragraph}>
          Our client adjusted their internal business flows to be more efficient and transparent. An
          additional value of digital transformation is the provided level of customization, which
          is currently considered as an option to extend the Fine app presence to other business
          domains, locations and target audiences.
        </p>
      </div>
      <Img fluid={data.screen5.childImageSharp.fluid} className={itemStyles.container} alt="" />
    </Layout>
  );
}

export default AnuelCase;
